<template>
    <div>

        <div class="container">
            <div class="row">
                <div class="col" style="padding-bottom:40px">
                    <div class="fale-conosco-titulo"> {{ categoria.titulo }}</div>
                    <div class="fale-conosco-subtitulo">Rede Credenciada</div>
                </div>
            </div>
        </div>

        <div class="container">
            <div class="row align-items-center mb-4">
                <div class="col-auto">
                    <router-link to="/rede-credenciada" class="btn-mults-do-vale"> Voltar </router-link>
                </div>
                <div v-if="cidade.codigo>0" class="col-auto">
                    <i class="las la-map-marker"></i>
                    <strong>{{ cidade.nome }}</strong>
                </div>
            </div>

            <loading v-show="loading" />

            <div v-for="(row, index) in registros"  :key="index" class="card mb-4">
                <div class="card-body">
                    <div class="row align-items-center">
                        <div class="col-auto">
                            <img :src="row.logomarca" style="max-width:100px">
                        </div>
                        <div class="col">
                            <strong>{{ row.nome }}</strong> <br>
                            {{ row.telefones }}<br>
                            {{ row.endereco }}, {{ row.bairro }} <br>
                            {{ row.cidade }} - {{ row.uf }}
                            <p v-if="row.status_enviapagamento">
                                <b-badge variant="success"><b-icon icon="check2-circle"></b-icon> Enviepag</b-badge>
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import loading from '../components/Loading';

export default {
  name: 'NossosCartoes',
  components:{
      loading
  },
  data() {
    return {
        registros: [],
        categoria: {},
        loading: true,
        url: this.$route.params.url,
    }
  },
  mounted(){
    
    this.carregaRede();

  },
  methods: {
    carregaRede(){
        this.loading = true;
        this.$axios.get(process.env.VUE_APP_URL+'rede-recredenciada/'+this.url+'?codigo_cidade='+this.cidade.codigo+'&codigo_cartao='+this.cartao)
        .then((response) => {

            const data = response.data;
            this.registros = data.registros;
            this.categoria = data.categoria;
            this.loading = false;

        })
        .catch(() => {
            
            this.loading = false;

        });

    },
  },
  computed: {
    ...mapState('site', ['cidade','cartao']),
  }
}
</script>  