<template>
    <div>
      
        <div class="container">
            <div class="row">
                <div class="col-12" style="padding-bottom:20px">
                    <div class="fale-conosco-titulo">Suporte</div>
                </div>
            </div>
        </div>

       

        <div class="container mb-4">
             <nav aria-label="breadcrumb">
                <ol class="breadcrumb">
                    <li class="breadcrumb-item"><router-link to="/suporte">Suporte</router-link></li>
                    <li class="breadcrumb-item"><router-link :to="'/suporte/'+categoria.url">{{ categoria.titulo }}</router-link></li>
                    <li class="breadcrumb-item active" aria-current="page">{{ post.titulo }}</li>
                </ol>
            </nav>
            <strong class="tx-22">{{ post.titulo }}</strong>
            <p>{{ post.subtitulo }}</p>
        </div>
        <div class="container mb-4" v-html="post.texto"></div>
            
      <loading v-show="loading" />
      <div class="container" v-html="pagina" style="padding-bottom:40px"></div>

    </div>
</template>

<script>
import loading from '../components/Loading';

export default {
  name: 'SuportePagina',
  components:{
      loading
  },
  data() {
    return {
      url: this.$route.params.url,
      loading:true,
      post:{},
      categoria:{},
      
    }
  },
  mounted(){

    this.carregaPagina();

  },
  methods: {
    carregaPagina(){

        this.loading = true;
        this.$axios.get(process.env.VUE_APP_URL+'suporte/pagina/'+this.url)
        .then((response) => {

            const data = response.data;
            this.loading = false;
            this.post = data.post;
            this.categoria = data.categoria;

        })
        .catch(() => {
            this.loading = false;
        });

    },

  }
}
</script>  