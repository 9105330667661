<template>
    <div>
      
        <div class="container">
            <div class="row">
                <div class="col-12" style="padding-bottom:20px">
                    <div class="fale-conosco-titulo">Suporte</div>
                </div>
            </div>
        </div>
        <div class="container mb-4">
            <p>
                Acesse o conteúdo por área de conhecimento.
            </p>
        </div>

        <loading v-show="loading" />
      
        <div class="container mb-4">
            <div class="row">
                <div class="col-md-3" v-for="(categoria,index) in categorias" :key="index">
                    <router-link :to="'/suporte/'+categoria.url" class="card mb-4">
                        <div class="card-body text-center">
                            <strong>{{ categoria.titulo }}</strong>
                            <p class="mb-0 tx-14">{{ categoria.subtitulo }}</p>
                        </div>
                    </router-link>
                </div>
            </div>
        </div>

    </div>
</template>

<script>
import loading from '../components/Loading';

export default {
  name: 'Home',
  components:{
      loading
  },
  data() {
    return {
      url: this.$route.params.url,
      loading:true,
      pagina:''
    }
  },
  mounted(){

    this.carregaPagina();


  },
  methods: {
    carregaPagina(){

        this.loading = true;
        this.$axios.get(process.env.VUE_APP_URL+'suporte')
        .then((response) => {

            const data = response.data;
            this.loading = false;
            this.categorias = data.categorias;

        })
        .catch(() => {
            this.loading = false;
        });

    },

  }
}
</script>  