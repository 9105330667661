<template>
    <div>
        <div class="container" style="padding-bottom:40px">
            <div class="fale-conosco-titulo">Blog</div>
            <div class="fale-conosco-subtitulo">Dicas & Novidades</div>
        </div>
        <div class="container">
            <div class="row justify-content-center">
                <div class="col-md-6">
                    <div class="blog" v-for="(blog) in posts" :key="blog.codigo_post">
                        <p class="mb-0 blog-data">{{ blog.data }}</p>
                        <h1 class="blog-h1">{{ blog.titulo }}</h1>
                        <img class="blog-img" :src="blog.imagem"/>
                        <p class="blog-sub">{{ blog.subtitulo }}</p>

                        <router-link class="ler-mais" :to="blog.url">Continue lendo</router-link>
                    </div>
                </div>
            </div>
            <div v-show="mostra_carregamais" class="row justify-content-center">
                <div class="col-auto">
                    <button class="carrega-mais" @click="carregaPosts()">Carrega mais</button>
                </div>
            </div>
        </div>
    </div>
</template>
<style>
.blog{
    display: flow-root;
    border-bottom: 1px dashed #ccc;
    margin-bottom: 20px;
    padding-bottom: 15px;
}
.blog-h1{
    font-size: 28px;
    font-weight: bold;
    padding: 0 0 5px 0;
    color: #334690;
}
.blog-data{
    font-size: 14px;
}
.blog-img{
    max-width: 100%;
    border-radius: 4px;
}
.blog-sub{
    padding: 15px 0;
    font-size: 16px;
    
}
.carrega-mais{
    background: #ffba09;
    border: none;
    border-radius: 30px;
    padding: 10px 20px;
    font-size: 16px;
    margin-bottom: 25px;
}
.ler-mais:hover{
    color: #ffba09;
}
.ler-mais{
    background: #334690;
    border: none;
    border-radius: 30px;
    padding: 10px 20px;
    font-size: 14px;
    color: #fff;
    display: inline-block;
}
</style>
<script>

export default {
    name: 'NossosCartoes',
    data() {
    return {
        loading:false,
        posts: [],
        page: 1,
        mostra_carregamais: false
    }
    },
    mounted(){
        
        this.carregaPosts();
        
    },
    methods: {
        carregaPosts(){

            this.loading = true;
            this.$axios.get(process.env.VUE_APP_URL+'blog?page='+this.page)
            .then((response) => {
                const data = response.data;
                this.loading = false;

                data.data.forEach(element => {
                    this.posts.push(element);
                });
                
                if(data.last_page==this.page){
                    this.mostra_carregamais = false;
                }else{
                    this.mostra_carregamais = true;
                    this.page = this.page + 1;
                }
            })
            .catch(() => {
                this.loading = false;
            });

        },
    }
}
</script>  