<template>
    <div>

        <div class="container" style="padding-bottom:40px">
            <div class="row align-items-center">
                <div class="col">
                    <div class="fale-conosco-titulo">Nossos cartões</div>
                    <div class="fale-conosco-subtitulo">Nossos cartões são pensados em você e seu négocio!</div>
                </div>
                <div class="col-auto">
                    <img src="iconeshome.png"/>
                </div>
            </div>
        </div>

        <div style="background-image: url('./bg.png'); background-size: cover;">
        <loading v-show="loading" />

        <div v-for="(nossocartao, index) in nossocartoes" :key="index" class="cartao-com-fundo">
            <div class="container">
                <div class="row align-items-center">
                    <div class="col-md-6">
                        <div class="nosso-cartao-texto">
                            <h4>{{ nossocartao.titulo }}</h4>
                            <p>{{ nossocartao.texto }}</p>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <img :src="nossocartao.imagem" style="max-width: 100%; border-radius: 10px;"/>
                    </div>
                </div>
            </div>
        </div>

    </div>

    </div>
</template>

<script>

export default {
    name: 'NossosCartoes',
    data() {
    return {
        loading:false,
        nossocartoes:[]
    }
    },
    mounted(){
        
        this.carregaCartoes();
        
    },
    methods: {
        carregaCartoes(){

            this.loading = true;
            this.$axios.get(process.env.VUE_APP_URL+'cartoes')
            .then((response) => {
                const data = response.data;
                this.loading = false;

                this.nossocartoes = data.cartoes;

            })
            .catch(() => {
                this.loading = false;
            });

        },
    }
}
</script>  