<template>
    <div>
      
      <div class="container" style="padding-bottom:40px">
        <div class="fale-conosco-titulo">{{ titulo }}</div>
      </div>
      <loading v-show="loading" />
      <div class="container" v-html="pagina" style="padding-bottom:40px"></div>

    </div>
</template>

<script>
import loading from '../components/Loading';

export default {
  name: 'Home',
  components:{
      loading
  },
  data() {
    return {
      url: this.$route.params.url,
      loading:true,
      pagina:'',
      titulo:''
    }
  },
  mounted(){

    this.carregaPagina();

  },
  methods: {
    carregaPagina(){

        this.loading = true;
        this.$axios.get(process.env.VUE_APP_URL+'pagina/'+this.url)
        .then((response) => {
            const data = response.data;
            this.loading = false;
            this.pagina = data.conteudo;
            this.titulo = data.titulo;

        })
        .catch(() => {
            this.loading = false;
        });

    },

  }
}
</script>  