<template>
    <div>

        <div class="container">
            <div class="col-12" style="padding-bottom:40px">
                <div class="fale-conosco-titulo">Rede Credenciada</div>
                <div class="fale-conosco-subtitulo">Tudo que você precisa</div>
            </div>
        </div>
        <div class="container">

            <div class="row align-items-center mb-4">
                <div class="col-auto">
                    <button type="button"  v-b-modal.modal-1 data-bs-toggle="modal" data-bs-target="#exampleModal" class="btn btn-outline-secondary">
                        <i class="las la-map-marker"></i>
                        <strong>{{ cidade.nome }}</strong>
                    </button>
                </div>
                <div class="col">
                    <b-form-group v-slot="{ ariaDescribedby }" class="mb-0">
                        <b-form-radio-group
                            id="checkbox-group-1"
                            v-model="selected"
                            :options="options"
                            :aria-describedby="ariaDescribedby"
                            name="flavour-1"
                        ></b-form-radio-group>
                    </b-form-group>
                </div>
            </div>            

            <loading v-show="loading" />
            <div class="row">
                <div v-for="(categoria, index) in categorias"  :key="index"  class="col-md-2">
                    <router-link :to="'/rede-credenciada/'+categoria.url" class="icone_rede">
                        <div class="icone_rede_img"><img :src="categoria.icone"></div>
                        <div class="icone_rede_titulo">
                            <span>{{ categoria.titulo }}</span>
                        </div>
                    </router-link>                                    
                </div>
            </div>
        </div>

    <b-modal id="modal-1" title="Alterar Cidade" hide-footer>
        <b-list-group>
            <b-list-group-item button @click="selecionaCidade(cidade)" v-for="(cidade,index) in cidades" :key="index">{{ cidade.nome }}</b-list-group-item>
        </b-list-group>
    </b-modal>

    </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import loading from '../components/Loading';

export default {
  name: 'NossosCartoes',
  components:{
      loading
  },
  data() {
    return {
        categorias: [],
        loading: true,
        options:[],
        cidades:[],
    }
  },
  mounted(){
    
    this.carregaRede();

  },
  methods: {
    ...mapActions('site', ['setCidade','setCartao']),
    carregaRede(){

        this.loading = true;
        this.$axios.get(process.env.VUE_APP_URL+'rede-recredenciada?codigo_cidade='+this.cidade.codigo+'&codigo_cartao='+this.cartao)
        .then((response) => {
            const data = response.data;
            this.loading = false;
            this.categorias = data.categorias;
            this.cidades = data.cidades;
            this.options = data.cartoes;
        })
        .catch(() => {
            this.loading = false;
        });

    },
    selecionaCidade(cidade){
        this.setCidade(cidade);
        this.carregaRede();
        this.$bvModal.hide('modal-1');
    }
  },
  computed: {
    ...mapState('site', ['cidade','cartao']),
     selected: {
        get () {
            return this.$store.state.site.cartao
        },
        set (value) {
            this.setCartao(value);
            this.carregaRede();
        }
    }
  },
  watch:{
      selected: {
      handler(newValue, oldValue) {
        this.carregaRede();
      },
    }
  }

}
</script>  