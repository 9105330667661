<template>
    <div class="contato">

        <div class="container">
            <div class="row align-items-end">
                <div class="col-6" style="padding-bottom:40px">

                    <div class="fale-conosco-titulo">Fale Conosco</div>
                    <div class="fale-conosco-subtitulo">Estamos sempre aqui para ajudar, o que podemos fazer por você?</div>

                    <div class="fale-conosco-duvidas">Caso tenha alguma dúvida ou está interessado em nossos cartões, entre em contato conosco por:</div>

                    <div class="fale-conosco-icons">
                        <div class="row align-items-center">
                            <div class="col-auto">
                                <b-icon-telephone-fill></b-icon-telephone-fill>
                            </div>
                            <div class="col">
                                (66) 3532-3800/3600
                            </div>
                        </div>
                    </div>
                    <div class="fale-conosco-icons">
                        <div class="row align-items-center">
                            <div class="col-auto">
                                <b-icon-envelope></b-icon-envelope>
                            </div>
                            <div class="col">
                                contato@multvale.com.br
                            </div>
                        </div>
                    </div>
                    <div class="fale-conosco-icons">
                        <div class="row align-items-center">
                            <div class="col-auto">
                                <b-icon-geo-alt-fill></b-icon-geo-alt-fill>
                            </div>
                            <div class="col">
                                Av. das Figueiras, 675 - St. Comercial, Sinop - MT
                            </div>
                        </div>
                    </div>

                    
                </div>
                <div class="col-6">
                    <div v-if="status==0" class="caixa-contato">
                        <b-form-group label="Nome Completo">
                            <b-form-input
                            v-model="form.nome"
                            placeholder="Preencha seu nome"
                            required
                            ></b-form-input>
                        </b-form-group>

                        <b-form-group label="Email">
                            <b-form-input
                            v-model="form.email"
                            placeholder="Preencha seu email"
                            required
                            ></b-form-input>
                        </b-form-group>

                        <b-form-group label="Telefone">
                            <b-form-input
                            v-model="form.telefone"
                            placeholder="Preencha seu telefone"
                            required
                            ></b-form-input>
                        </b-form-group>

                        <b-form-group label="Mensagem">
                            <b-form-textarea
                            v-model="form.mensagem"
                            placeholder="Preencha sua mensagem"
                            required
                            rows="3"
                            ></b-form-textarea>
                        </b-form-group>
                        <button @click="enviar()" class="contato-enviar">{{ loading ? 'Enviado...' : 'Enviar' }}</button>
                    </div>
                    <div v-if="status==1" class="caixa-contato">
                        <b-alert show variant="success"><i class="las la-check-circle"></i> Email enviado com sucesso!</b-alert>
                    </div>
                    <div v-if="status==2" class="caixa-contato">
                        <b-alert show variant="danger">Erro ao enviar!</b-alert>
                    </div>
                </div>
            </div>
        </div>

        <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d15618.906345375837!2d-55.5006068!3d-11.854402!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x2409b36502a6d50d!2sMult%20Vale%20Cart%C3%B5es%20de%20Benef%C3%ADcios!5e0!3m2!1spt-BR!2sbr!4v1649191507457!5m2!1spt-BR!2sbr" width="100%" height="450" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
    
    </div>
</template>

<script>

export default {
    name: 'Home',
    data() {
        return {
            slide: 0,
            form:{},
            status: 0,
            loading:false
        }
    },
    mounted(){

    },
    methods: {
        enviar(){

            if(this.loading==true){ return false; }

            this.loading = true;
            this.$axios.post(process.env.VUE_APP_URL+'contato', this.form)
            .then((response) => {
                
                const data = response.data;
                
                if(data.status==true){
                    this.status = 1;
                }else{
                    this.status = 2;
                }
            })
            .catch(() => {
                this.loading = false;
                this.status = 2;
            });

        },
    }
}
</script>