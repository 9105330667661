<template>
    <div>
      
        <div class="container">
            <div class="row">
                <div class="col-12" style="padding-bottom:20px">
                    <div class="fale-conosco-titulo">Suporte</div>
                </div>
            </div>
        </div>
        <div class="container mb-4">
            <nav aria-label="breadcrumb">
                <ol class="breadcrumb">
                    <li class="breadcrumb-item"><router-link to="/suporte">Suporte</router-link></li>
                    <li class="breadcrumb-item active" aria-current="page">{{ categoria.titulo }}</li>
                </ol>
            </nav>

            <strong class="tx-18">{{ categoria.titulo }}</strong>
            <p>{{ categoria.subtitulo }}</p>
        </div>

        <loading v-show="loading" />
      
        <div class="container mb-4">

            <div class="row">
                <div class="col-md-12" v-for="(post,index) in posts" :key="index">
                    <router-link :to="'/suporte/'+categoria.url+'/'+post.url" class="card mb-4">
                        <div class="card-body">
                            <strong>{{ post.titulo }}</strong>
                            <p class="mb-0 tx-14">{{ post.subtitulo }}</p>
                        </div>
                    </router-link>
                </div>
            </div>

        </div>

    </div>
</template>

<script>
import loading from '../components/Loading';

export default {
  name: 'Home',
  components:{
      loading
  },
  data() {
    return {
      url: this.$route.params.url,
      loading:true,
      categoria:{},
      posts: {}
    }
  },
  mounted(){

    this.carregaPagina();


  },
  methods: {
    carregaPagina(){

        this.loading = true;
        this.$axios.get(process.env.VUE_APP_URL+'suporte/'+this.url)
        .then((response) => {

            const data = response.data;
            this.loading = false;
            this.posts = data.posts;
            this.categoria = data.categoria;

        })
        .catch(() => {
            this.loading = false;
        });

    },

  }
}
</script>  